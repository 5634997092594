import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { PanelType } from "./panel-type";

interface PanelProps {
    className?: string;
    isOpen: boolean;
    isLightDismiss?: boolean;
    type: PanelType;
    headerText: string;
    hasCloseButton?: boolean;
    onDismiss?: () => void;
    children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyCallback: () => void = () => {};

const widths = {
    [PanelType.smallFluid]: "max-w-md",
    [PanelType.smallFixedNear]: "max-w-md",
    [PanelType.medium]: "max-w-md",
    [PanelType.large]: "max-w-2xl",
    [PanelType.largeFixed]: "max-w-2xl",
    [PanelType.extraLarge]: "max-w-4xl",
};

export const Panel: React.FC<PanelProps> = props => {
    const { isOpen, headerText, onDismiss, type, hasCloseButton } = props;
    const realOnDismiss = onDismiss ?? emptyCallback;
    const panelWidth = widths[type];
    const showCloseButton = hasCloseButton === false ? false : true;
    return (
        <Transition appear={true} show={isOpen}>
            <Dialog as="div" className="relative z-1500" onClose={realOnDismiss}>
                <TransitionChild
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <TransitionChild
                                enter="transform transition ease-in-out duration-300 sm:duration-500"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-300 sm:duration-500"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full">
                                <DialogPanel
                                    className={`pointer-events-auto w-screen ${panelWidth}`}>
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <DialogTitle className="text-lg font-medium text-gray-900">
                                                    {headerText}
                                                </DialogTitle>
                                                <div className="ml-3 flex h-7 items-center">
                                                    {showCloseButton ? (
                                                        <button
                                                            type="button"
                                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-500"
                                                            onClick={realOnDismiss}>
                                                            <span className="sr-only">
                                                                Close panel
                                                            </span>
                                                            <XMarkIcon
                                                                className="h-6 w-6"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div className="app-component">
                                                <div className="panel-body">
                                                    {props.children}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
